import React from 'react';
import { graphql } from 'gatsby';

import HomePage from '../components/HomePage';

const IndexPage = ({ data }) => {
  // const { sanityHome } = data;

  return <HomePage {...data.sanityHome} />;
};

export const HomeQuery = graphql`
  query {
    sanityHome {
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default IndexPage;
