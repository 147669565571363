import React from 'react';

import styled from 'styled-components';

import Content from './Content';
import Nav from './Nav';

const HomePage = ({ _rawContent }) => {
  return (
    <StyledPage>
      <Content>{_rawContent}</Content>
      <Nav home />
    </StyledPage>
  );
};

const StyledPage = styled.div``;

export default HomePage;
